import React, { useEffect } from "react";
import './skills.css';
import points from '../../assets/points.png'
import rectangle from '../../assets/rectangle.png'
import logo from '../../assets/logo.png'

const Skills = () => {
    useEffect(() => {
        const getRandomPosition = () => {
          const getRandomValue = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);
    
          return {
            top: getRandomValue(-200, 200) + "px", // Adjust the range as needed
            left: getRandomValue(-200, 200) + "px", // Adjust the range as needed
          };
        };
    
        const elements = document.querySelectorAll(".random-position-element");
    
        elements.forEach((element) => {
          const { top, left } = getRandomPosition();
          element.style.top = top;
          element.style.left = left;
        });
      }, []); // Empty dependency array ensures that this effect runs only once after initial render
    

    return (
        <div className="skills-container">
            <div className="skills-container-title">
                <h2><span>#</span>Skills</h2>
                <div className="skills-container-title_line"></div>
            </div>
            <div className='skills-containers-content'>

                {/* <img className='absolute  skills-container-images-1' src={points} alt="Points" /> */}
                <img className='absolute skills-container-images-2' src={points} alt="Points" />
                <img className='absolute skills-container-images-4' src={rectangle} alt="Rectangle" />

                <img className='absolute skills-container-images-5' src={logo} alt="Logo" />
                <img className='absolute skills-container-images-6' src={rectangle} alt="Rectangle" />

                <div className=' block items-center'>
                    <table className='skills-container-table-1'>
                        <tr>
                            <th>Languages</th>
                        </tr>
                        <tr>
                            <td>JavaScript,
                                Python,
                                Java,
                                Kotlin,
                                C,
                                C#,
                                C++,
                                TypeScript,
                                SQL,
                                Solidity,
                                Rust,
                                HTML/CSS,
                                Shell Scripting (Bash)
                            </td>
                        </tr>
                    </table>

                    <table className='skills-container-table-2'>
                        <tr>
                            <th>Frameworks</th>
                        </tr>
                        <tr>
                            <td>Node.js,
                                Flask
                                Spring Boot
                                ASP.NET Core,
                                .NET Framework/Core,
                                React,
                                Next,
                                Angular,
                                Vue.js,
                                Web3.js,
                                Ethers.js,
                                Bootstrap,
                                Tailwind CSS,
                                Truffle
                            </td>
                        </tr>
                    </table>

                    <table className='skills-container-table-3'>
                        <tr>
                            <th>Tools</th>
                        </tr>
                        <tr>
                            <td>Git,
                                Apache Maven,
                                Jenkins,
                                JIRA,
                                Docker,
                                VirtualBox,
                                Postman,
                                Selenium,
                                Visual Studio/Code,
                                Android Studio,
                                IntelliJ,
                                Ethereum Remix,
                                Ganache,
                                Metamask,
                                Infura,
                                Gradle,
                                Figma,
                                Blender,
                                Unity,
                            </td>
                        </tr>
                    </table>

                </div>


            </div>




        </div>
    )
}

export default Skills