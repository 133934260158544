import React from 'react'
import me from '../../assets/myself.jpeg'
import './intro.css'

const Intro = () => {
    return (
        <section id="home">
            <div className="intro mt-4">
                <div className="intro__elements flex flex-col md:flex-row">
                    <div className="intro__text">
                        <h1>Unlocking the potential of technology through innovation</h1>
                        <h2>Aziz Khalledi, a <span>Software Developer</span></h2>
                        <p>Hello there! I'm Aziz Khalledi, a Software Developer who is passionate about creating innovative and functional solutions to real-world problems. Whether it's developing <span>web applications</span>, <span>mobile apps</span> or <span>building complex systems</span>, I enjoy working with cutting-edge technology and pushing the boundaries of what's possible.  </p>
                    </div>
                    <img src={me} alt="Me" className="intro__photo w-max md:w-0" />
                </div>
            </div>
        </section>

    );
};

export default Intro