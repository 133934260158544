import React from 'react'
import './header.css'
import '../../components/navbar/Navbar'
import Navbar from '../../components/navbar/Navbar'
import Logo from '../../components/logo/Logo'
const Header = () => {
    return (
        <div className='folio__header'>
            <div className='folio__header-elements'>
                <Logo></Logo>
                <Navbar></Navbar>
            </div>
            <div className="line"></div>

        </div>
    )
}

export default Header