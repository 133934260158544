import React from 'react'
import './contact.css';
import discord from '../../assets/discord.png'
import email from '../../assets/Email.png'

const Contact = () => {
    return (
        <section id='contacts'>
            <div className="contact-container">
                <div className="contact-container-title">
                    <h2><span>#</span>Contact</h2>
                    <div className="contact-container-title_line"></div>
                </div>
                <div className='contact__elements gap-2 flex flex-col md:flex-row'>

                    <div className="contact__text">
                        <br>
                        </br>
                        <br></br>
                        <p>Feel free to contact me to discuss your project needs and how I can help you create innovative and robust software solutions.</p>
                        <p>You can reach me by email at <span>zizou.freelance@gmail.com</span>, or connect with me on LinkedIn at <span><a href='https://www.linkedin.com/in/aziz-khalledi'>[aziz-khalledi]</a></span>. I look forward to hearing from you!</p>
                    </div>
                    <table className='contact-container-table-1 w-max min-w-max '>
                        <tr>
                            <th>Message me here!</th>
                        </tr>
                        <tr>
                            <td>
                                
                                <div className='contact-container-discord w-max flex flex-row items-center'>
                                    <img className='contact-container-images-1' src={discord} alt="Points" />
                                    <p>!Azizkhalledi</p>
                                </div>
                                <div className='contact-container-email  flex flex-row items-center'>
                                    <img className='contact-container-images-1' src={email} alt="Points" />
                                    <p>zizou.freelance@gmail.com</p>
                                </div>


                            </td>
                        </tr>
                    </table>
                </div>
            </div>

        </section>



    )
}

export default Contact