import React from 'react'
import './footer.css'
import github from '../../assets/github.ico'
import medium from '../../assets/medium.ico'
import twitter from '../../assets/twitter.ico'
import facebook from '../../assets/facebook.ico'
import instagram from '../../assets/instagram.ico'
import discord from '../../assets/discord.ico'

const Footer = () => {
    return (
        <footer className="footer">
            <div className="line"></div>
            <div className="footer__content">
                <p>&copy; 2023 Aziz Khalledi</p>
                <nav className="footer__links">
                    <div>
                        <a href="#">Home</a>
                        <a href="#projects">Projects</a>
                        <a href="#contacts">Contact</a>
                    </div>
                    <br></br>
                    <div className='flex flex-row'>
                        <a href="https://github.com/AzizFacilex">
                            <img src={github}></img>
                        </a>
                        <a href="https://medium.com/@khallediaziz">
                            <img src={medium}></img>
                        </a>
                        <a href="https://twitter.com/AzizBytes">
                            <img src={twitter}></img>
                        </a>
                        <a href="https://facebook.com/Facilex.Aziz">
                            <img src={facebook}></img>
                        </a>
                        {/* <a href="https://instagram.com/yourusername">
                            <img src={instagram}></img>
                        </a> */}
                        <a href="https://discord.gg/MfxT49fR">
                            <img src={discord}></img>
                        </a>
                    </div>

                </nav>
            </div>
        </footer>
    );
}

export default Footer