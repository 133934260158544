import React from 'react'
import { Footer, Header, Projects, Skills, Aboutme, Contact } from './containers'
import { Intro } from './components'
import './App.css'

const App = () => {
    return (

        < div className="App w-screen" >
            <div className="gradient__bg">
                <Header />
            </div>
            <div className='main_elements'>
                <Intro></Intro>
                <Projects />
                <Skills />
                <Aboutme />
                <Contact />

            </div>
            <Footer />
        </div >
    )
}

export default App