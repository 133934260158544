import React from 'react'
import './logo.css'
import logo from '../../assets/logo2.svg'

const Logo = () => {
    return (
        <a href='#'>
            <div className='folio__logo'>
                <img className='logo-container' src={logo} alt="Logo" />
                <h2>!Zizou</h2>
            </div>
        </a>

    )
}

export default Logo