import React, { useState, useEffect } from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './projects.css';
import Project from '../../components/project/Project';
import points from '../../assets/points.png'
import babyBlooz from '../../assets/babyblooz.png'
import textGenius from '../../assets/textgenius.png'
import ethereum from '../../assets/ethereum.png'
import hadoop from '../../assets/hadoop.png'
import analisys from '../../assets/analisys.png'
import viewer from '../../assets/viewer.png'

const Projects = () => {

    const [sliderSettings, setSliderSettings] = useState({
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        arrows: true,
        centerPadding: "0px",
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
      });
    
      useEffect(() => {
        const updateSliderSettings = () => {
          const screenWidth = window.innerWidth;
    
          if (screenWidth < 768) {
            // Small screens, show 1 slide
            setSliderSettings({
              ...sliderSettings,
              slidesToShow: 1,
            });
          } else if (screenWidth < 1024) {
            // Medium screens, show 2 slides
            setSliderSettings({
              ...sliderSettings,
              slidesToShow: 2,
            });
          } else {
            // Larger screens, show 3 slides
            setSliderSettings({
              ...sliderSettings,
              slidesToShow: 3,
            });
          }
        };
        // Initial settings
    updateSliderSettings();

    // Update settings when the window is resized
    window.addEventListener("resize", updateSliderSettings);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("resize", updateSliderSettings);
    };
  }, []); // Empty dependency array ensures that this effect runs only once after initial render



    function PrevArrow(props) {
        const { onClick } = props;
        return (
            <div
                className="slick-arrow slick-prev"
                onClick={onClick}
            >
                <i className="fas fa-chevron-left"></i>
            </div>
        );
    }

    function NextArrow(props) {
        const { onClick } = props;
        return (
            <div
                className="slick-arrow slick-next"
                onClick={onClick}
            >
                <i className="fas fa-chevron-right"></i>
            </div>
        );
    }

    const projects = [
        { id: 1, image: textGenius, name: "TextGenius", description: "TextGenius is a Python program that uses deep learning and computer vision techniques to recognize and extract text from images." },
        { id: 2, image: ethereum, name: "ZizouEthereumCracker", description: "Crack Ethereum wallets by finding their Mnemonics, using 100% of CPU, logging to Blockchain Contract for use on multiple Nodes. " },
        { id: 3, image: hadoop, name: " Hadoop-SortWordLength", description: "Hadoop-SortWordLength: A Java program for sorting words in a text file by their length using Hadoop MapReduce framework." },
        { id: 4, image: analisys, name: "3DAnalysis", description: "A 3D Analysis program made with C# for optimal 3D printing." },
        { id: 5, image: viewer, name: "STL-Viewer", description: "A Simple program to view STL files made with OpenGL, C# " },
        { id: 6, image: babyBlooz, name: "BabyBlooz", description: "The project is an Arduino-based baby monitoring app that detects cries, movements, and soiling. It records temperature data and sends statistics and notifications to the user's phone, even if they are not home." },


        // Add more projects here
    ];


    return (
        <section id="projects">
            <div className="projects-container w-3/4 md:w-1/2">
                <img className="projects-container-images-1 absolute" src={points} alt="Points" />
                <div className="projects-container-title">
                    <h2>
                        <span>#</span>Projects
                    </h2>
                    <div className="projects-container-title_line"></div>
                </div>
                <Slider {...sliderSettings} >
                    {projects.map((project) => (
                        <div className="project-container " key={project.id}>
                            <Project
                                id={project.id}
                                image={project.image}
                                name={project.name}
                                description={project.description}
                            />
                        </div>
                    ))}
                </Slider>
                
            </div>
        </section>

    );

}

export default Projects;
