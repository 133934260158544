import React, { useState } from "react";
import './project.css';
import me from '../../assets/me.png'
import Modal from "react-modal";

const Project = (props) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    return (
        <div className="project ">
            <img src={props.image} alt="Project" onClick={openModal} />
            <div className="project__text whitespace-break-spaces" onClick={openModal}>
                <h3>{props.name}</h3>
                <p>{props.description}</p>
            </div>
            <Modal className="custom-modal-content z-50 w-5/6" isOpen={modalIsOpen} onRequestClose={closeModal}>
                <div className="project-modal">
                    <img src={props.image} alt="Project"></img>
                    <h2>{props.name}</h2>
                    <p>{props.description}</p>
                </div>
            </Modal>
        </div>
    );
};

export default Project;
