import React, { useState, useRef, useEffect } from "react";
import "./navbar.css";

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const mobileMenuRef = useRef(null);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  const handleOutsideClick = (event) => {
    if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
      closeMobileMenu();
    }
  };

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isMobileMenuOpen]);

  return (
    <div className="folio__navbar ">
      <div className="folio__navbar-head hidden md:flex">
        <div className="folio__navbar-head_container">
          <p>
            <a href="#">
              <span>#</span>Home
            </a>
          </p>
          <p>
            <a href="#projects">
              <span>#</span>Projects
            </a>
          </p>
          <p>
            <a href="#aboutme">
              <span>#</span>About Me
            </a>
          </p>
          <p>
            <a href="#contacts">
              <span>#</span>Contacts
            </a>
          </p>
        </div>
      </div>

      {/* Hamburger Menu for Mobile */}
      <div className="md:hidden">
        <button
          onClick={toggleMobileMenu}
          className="text-white focus:outline-none"
        >
          <svg
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            className="w-6 h-6"
          >
            <path d="M4 6h16M4 12h16m-7 6h7"></path>
          </svg>
        </button>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div
          ref={mobileMenuRef}
          className="md:hidden fixed top-0 right-0 h-full w-1/2 bg-gray-900 "
        >
          <div className="p-4 ">
            <button
              onClick={closeMobileMenu}
              className="text-white focus:outline-none float-right"
            >
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                className="w-6 h-6"
              >
                <path d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
            <div className=" border-t mt-10 gap-5 folio__navbar-head_container flex flex-col gap-2 p-4">
            <p>
              <a href="#" onClick={closeMobileMenu}>
                <span>#</span>Home
              </a>
            </p>
            <p>
              <a href="#projects" onClick={closeMobileMenu}>
                <span>#</span>Projects
              </a>
            </p>
            <p>
              <a href="#aboutme" onClick={closeMobileMenu}>
                <span>#</span>About Me
              </a>
            </p>
            <p>
              <a href="#contacts" onClick={closeMobileMenu}>
                <span>#</span>Contacts
              </a>
            </p>
            </div>
           
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
