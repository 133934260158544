import React from 'react'
import './aboutme.css';
import aboutme from '../../assets/aboutme.png'

const Aboutme = () => {
    return (
        <section id="aboutme">
            <div className="aboutme-container">
                <div className="aboutme-container-title">
                    <h2><span>#</span>About-me</h2>
                    <div className="aboutme-container-title_line"></div>
                </div>
                <div className='aboutme__elements flex flex-col'>
                    <div className="intro__text">
                        <br>
                        </br>
                        <br></br>
                        <p>Hello, I'm Aziz Khalledi!</p>
                        <br></br>

                        <p> I'm a a Berlin-based software developer <span>skilled in web, mobile, and desktop development.</span> As an <span>active GitHub contributor,</span> I'm always seeking new opportunities and projects to expand my knowledge and collaborate with other developers.
                        </p>
                        <br></br>
                        <p>With expertise in a variety of programming languages, including <span>Java, Python, C#, and Typescript,</span> I bring a diverse skill set to every project. Whether it's web development, mobile app development, or desktop software development, I'm dedicated to delivering high-quality solutions that solve complex problems. </p>
                        <br></br>

                        <p>I believe in <span>staying up-to-date with the latest technologies and techniques</span> to ensure that my solutions are always top-notch. Let's work together to create innovative and robust software solutions that drive success!</p>
                    </div>
                    <img src={aboutme} alt="Me" className="intro__photo  w-max" />
                </div>


            </div>
        </section>

    )
}

export default Aboutme